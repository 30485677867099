'use client';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { LogoIcon, LogoText } from '~/app/_components/ToneIcons';
import { LinkButton } from '../Button/Button';
import { Menu01, XMark } from '../StrokeIcons';
import { SvgIconButton } from '../SvgIconButton/SvgIconButton';
import styles from './NavSection.module.scss';

export const NavSection: React.FC = () => {
  const [opened, setOpened] = React.useState(false);

  return (
    <>
      <div className={clsx(styles.navWrapper, 'fullWidth')}>
        <div className={styles.content}>
          <Link href={'/'} className={styles.headerLogoWrap}>
            <LogoIcon />
            <LogoText />
          </Link>
          <div className={styles.spacer} />
          <div className={styles.buttons}>
            <Link className={styles.menuItem} href="/pricing">
              Pricing
            </Link>
            <Link className={styles.menuItem} href="/posts">
              Knowledge Center
            </Link>
            <Link className={styles.menuItem} href="/glossary">
              Glossary
            </Link>
            <Link className={styles.menuItem} href="/login">
              Log in
            </Link>
            <LinkButton href="/signup">Get Started</LinkButton>
          </div>
          <SvgIconButton
            size="large"
            className={styles.menuButton}
            icon={<Menu01 />}
            onClick={() => setOpened((o) => !o)}
          />
        </div>
      </div>

      <div className={clsx(styles.menu, opened ? styles.opened : undefined)}>
        <div className={styles.menuHeader}>
          <SvgIconButton
            size="large"
            className={styles.menuButton}
            icon={<XMark />}
            onClick={() => setOpened((o) => !o)}
          />
        </div>
        <div className={styles.menuContent}>
          <Link className={styles.menuButton} href="/pricing">
            Pricing
          </Link>
          <Link className={styles.menuButton} href="/posts">
            Knowledge Center
          </Link>
          <Link className={styles.menuButton} href="/glossary">
            Glossary
          </Link>
          <Link className={styles.menuButton} href="/login">
            Log in
          </Link>
          <LinkButton href="/signup">Get Started</LinkButton>
        </div>
      </div>
    </>
  );
};
